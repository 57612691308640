import { pipe, flatten, map } from 'ramda';
import { ARTICLES } from '../assets/articles';
import { SECTIONS } from '../assets/sections';
import { ArticleReader } from '../components/article-reader';
import { ArticlesBySectionList } from '../components/articles-by-section-list';
import { HomePage } from '../components/homepage';

export const buildRoutes = (): {
    path: string;
    componentParams: any;
    componentFn: any;
}[] => {
    const sectionPaths = pipe(
        Object.values,
        flatten,
        map((section) => ({
            path: `/sections/${section.id}`,
            componentParams: { section },
            componentFn: ArticlesBySectionList,
        }))
    )(SECTIONS);

    const articlePaths = ARTICLES.map((article) => ({
        path: `/sections/:sectionid/articles/${article.url}`,
        componentParams: { article },
        componentFn: ArticleReader,
    }));

    const homePage = {
        path: '/',
        componentParams: {},
        componentFn: HomePage,
    };

    return [...sectionPaths, ...articlePaths, homePage];
};
