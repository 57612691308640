import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getArticleDefsForSection } from '../helpers/article-helpers';
import './articles-by-section-list.css';

export const ArticlesBySectionList = ({ section }) => {
    const articles = getArticleDefsForSection(section.id);

    return (
        <div className='articles-by-section-list main-content-container'>
            <div className='book-page'>
                <h1>{section.title}</h1>
                <div className='divider div-transparent div-dot'></div>
                <div className='article-list-content'>
                    {articles.map((articleDef) => (
                        <div key={articleDef.id} className='article-link'>
                            <Link to={`articles/${articleDef.url}`}>
                                <ArrowForwardIcon />
                                {articleDef.title}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
