import { identity, descend, prop } from 'ramda';
import { ARTICLES } from '../assets/articles';

export const searchInTitles = (searchString: string) => {
    if (!searchString) return [];

    return ARTICLES.reduce((acc: any[], article) => {
        const title = article.title.toLowerCase();
        const keywords = searchString.toLowerCase().split(' ').filter(identity);

        const matchCount = keywords.filter((keyword) =>
            title.includes(keyword)
        ).length;

        if (matchCount === 0) return acc;

        return [...acc, { ...article, matchCount }];
    }, [])
        .sort(descend(prop('matchCount')))
        .slice(0, 5);
};
