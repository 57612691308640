import { propEq } from 'ramda';
import { ARTICLES } from '../assets/articles';

export const getArticleDefsForSection = (sectionId: number) => {
    return ARTICLES.filter(propEq('section', sectionId));
};

export const fetchArticleContent = (articleUrl: string) => {
    const articlePath = `/html_articles/${articleUrl}.html`;
    return fetch(articlePath).then((response) => response.text());
};

export const pingServer = (path, isNewSession) => {
    if (!path) return;

    const scriptPath = `/ping.php?path=${path}&isUniqueSession=${isNewSession}`;
    fetch(scriptPath);
};
