export const SECTIONS = {
  5: [
    {
      id: 3,
      title: "Az őskori ember",
    },
    {
      id: 4,
      title: "Az ókori kelet",
    },
    {
      id: 1,
      title: "Az ókori hellász",
    },
    {
      id: 2,
      title: "Az ókori Róma",
    },
    {
      id: 6,
      title: "A középkori Európa",
    },
    {
      id: 5,
      title: "A magyar történelem kezdetei",
    },
    {
      id: 10002,
      title: "Magyarország az árpádok idején",
    },
  ],
  6: [
    {
      id: 7,
      title: "Virágzó és hanyatló középkor Magyarországon",
    },
    {
      id: 10001,
      title: "Az újkor kezdetén",
    },
    {
      id: 10003,
      title: "Magyarország az újkor kezdetén",
    },
    {
      //???
      id: 10013,
      title: "A polgári átalakulás kora",
    },
    {
      // ???
      id: 10011,
      title: "Reformkor, forradalom és szabadságharc",
    },
  ],
  7: [
    {
      id: 10013,
      title: "A polgári átalakulás kora",
    },
    {
      id: 10014,
      title: "Magyarország a 18. században",
    },
    {
      id: 10011,
      title: "Reformkor, forradalom és szabadságharc",
    },
    {
      id: 10010,
      title: "A nemzetállamok kora",
    },
    {
      id: 10012,
      title: "Magyarország a dualizmus időszakában",
    },
    {
      id: 10015,
      title: "Az első világháború",
    },
  ],
  8: [
    {
      id: 10020,
      title: "Földünk a két világháború között",
    },
    {
      id: 10021,
      title: "Hazánk a két világháború között",
    },
    {
      id: 10019,
      title: "A második világháború",
    },
    {
      id: 10016,
      title: "A kétpólusú világ",
    },
    {
      id: 10022,
      title: "Magyarország a szovjet táborban",
    },
  ],
};
