export const ARTICLES = [
    {
        id: 1,
        created: '2011-03-24 04:57:23',
        updated: '2012-12-23 09:45:08',
        url: 'a-gorog-perzsa-haboruk',
        title: 'A görög-perzsa háborúk',
        section: 1,
        counter: 18566,
    },
    {
        id: 2,
        created: '2011-03-24 04:57:23',
        updated: '2012-12-23 09:45:46',
        url: 'athen-fenykora-es-bukasa',
        title: 'Athén fénykora és bukása',
        section: 1,
        counter: 30838,
    },
    {
        id: 74,
        created: '2013-02-03 15:44:38',
        updated: '2013-03-01 16:16:16',
        url: 'matyas-uralkodasa',
        title: 'Mátyás uralkodása',
        section: 7,
        counter: 9515,
    },
    {
        id: 3,
        created: '2011-03-24 04:57:23',
        updated: '2011-03-24 04:57:43',
        url: 'augustus-es-kora',
        title: 'Augustus és kora',
        section: 2,
        counter: 6541,
    },
    {
        id: 58,
        created: '2011-04-14 17:19:41',
        updated: '2011-04-14 17:25:18',
        url: 'a-haromszor-koronazott-kiraly',
        title: 'A háromszor koronázott király',
        section: 7,
        counter: 8903,
    },
    {
        id: 4,
        created: '2011-03-24 04:57:23',
        updated: '2011-03-24 04:57:44',
        url: 'az-ember-szuletese',
        title: 'Az ember születése',
        section: 3,
        counter: 12370,
    },
    {
        id: 5,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:44',
        url: 'az-oskori-ember-mindennapjai-',
        title: 'Az őskori ember mindennapjai ',
        section: 3,
        counter: 23563,
    },
    {
        id: 6,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:45',
        url: 'az-ujkokori-valtozasok',
        title: 'Az újkőkori változások',
        section: 3,
        counter: 10784,
    },
    {
        id: 7,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:45',
        url: 'muveszek-es-mesterek',
        title: 'Művészek és mesterek',
        section: 3,
        counter: 13645,
    },
    {
        id: 8,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:46',
        url: 'a-piramisok-istenei',
        title: 'A piramisok istenei',
        section: 4,
        counter: 6472,
    },
    {
        id: 9,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:46',
        url: 'a-piramisok-nepe',
        title: 'A piramisok népe',
        section: 4,
        counter: 7259,
    },
    {
        id: 10,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:47',
        url: 'az-okori-india',
        title: 'Az ókori India',
        section: 4,
        counter: 15209,
    },
    {
        id: 11,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:48',
        url: 'az-okor-kina',
        title: 'Az ókor Kína',
        section: 4,
        counter: 10523,
    },
    {
        id: 12,
        created: '2011-03-24 04:57:24',
        updated: '2013-03-01 16:08:39',
        url: 'az-oszovetseg-nepe',
        title: 'Az ószövetség népe',
        section: 4,
        counter: 27714,
    },
    {
        id: 13,
        created: '2011-03-24 04:57:24',
        updated: '2011-03-24 04:57:49',
        url: 'egyiptom-a-nilus-ajandeka',
        title: 'Egyiptom a Nílus ajándéka',
        section: 4,
        counter: 16875,
    },
    {
        id: 14,
        created: '2011-03-24 04:57:25',
        updated: '2011-03-24 04:57:49',
        url: 'kereskedok-es-hoditok',
        title: 'Kereskedők és hódítók',
        section: 4,
        counter: 7229,
    },
    {
        id: 15,
        created: '2011-03-24 04:57:25',
        updated: '2011-03-24 04:57:50',
        url: 'mezopotania',
        title: 'Mezopotánia',
        section: 4,
        counter: 16499,
    },
    {
        id: 16,
        created: '2011-03-24 04:57:25',
        updated: '2011-03-24 04:57:50',
        url: 'sparta_-a-katonaallam-',
        title: 'Spárta, a katonaállam ',
        section: 1,
        counter: 22642,
    },
    {
        id: 17,
        created: '2011-03-24 04:57:25',
        updated: '2011-03-24 04:57:51',
        url: 'hanyatlas-es-fejlodes-',
        title: 'Hanyatlás és fejlődés ',
        section: 1,
        counter: 6540,
    },
    {
        id: 18,
        created: '2011-03-24 04:57:25',
        updated: '2011-03-24 04:57:51',
        url: 'az-okori-olimpiak-',
        title: 'Az ókori olimpiák ',
        section: 1,
        counter: 14809,
    },
    {
        id: 19,
        created: '2011-03-24 04:57:25',
        updated: '2011-03-24 04:57:52',
        url: 'az-atheni-tarsadalom-es-allam',
        title: 'Az Athéni társadalom és állam',
        section: 1,
        counter: 19345,
    },
    {
        id: 20,
        created: '2011-03-24 04:57:25',
        updated: '2011-03-24 04:57:53',
        url: 'a-vilaghodito-nagy-sandor-_alexandrosz_-',
        title: 'A világhódító Nagy Sándor (Alexandrosz) ',
        section: 1,
        counter: 17242,
    },
    {
        id: 21,
        created: '2011-03-24 04:57:26',
        updated: '2011-03-24 04:57:53',
        url: 'a-trojai-haboru',
        title: 'A trójai háború',
        section: 1,
        counter: 13332,
    },
    {
        id: 22,
        created: '2011-03-24 04:57:26',
        updated: '2011-03-24 04:57:54',
        url: 'a-gorog-tortenelem-hajnalan',
        title: 'A görög történelem hajnalán',
        section: 1,
        counter: 13741,
    },
    {
        id: 23,
        created: '2011-03-24 04:57:26',
        updated: '2011-03-24 04:57:54',
        url: 'a-gorog-istenek',
        title: 'A görög istenek',
        section: 1,
        counter: 18733,
    },
    {
        id: 24,
        created: '2011-03-24 04:57:26',
        updated: '2012-02-17 14:31:41',
        url: 'a-csalad-es-gyermek-az-okori-gorogorszagban',
        title: 'A család és gyermek az ókori Görögországban',
        section: 1,
        counter: 17192,
    },
    {
        id: 29,
        created: '2011-03-24 04:57:26',
        updated: '2012-02-17 14:25:38',
        url: 'roma-szuletese',
        title: 'Róma születése',
        section: 2,
        counter: 8246,
    },
    {
        id: 30,
        created: '2011-03-24 04:57:26',
        updated: '2011-03-24 04:57:56',
        url: 'a-koztarsasag-kialakulasa',
        title: 'A köztársaság kialakulása',
        section: 2,
        counter: 6822,
    },
    {
        id: 31,
        created: '2011-03-24 04:57:26',
        updated: '2011-03-24 04:57:56',
        url: 'a-hodito-roma',
        title: 'A hódító Róma',
        section: 2,
        counter: 17129,
    },
    {
        id: 32,
        created: '2011-03-24 04:57:27',
        updated: '2011-03-24 04:57:57',
        url: 'a-koztarsasag-valsaga',
        title: 'A köztársaság válsága',
        section: 2,
        counter: 8414,
    },
    {
        id: 33,
        created: '2011-03-24 04:57:27',
        updated: '2011-03-24 04:57:58',
        url: 'utban-a-csaszarsag-fele',
        title: 'Útban a császárság felé',
        section: 2,
        counter: 10311,
    },
    {
        id: 35,
        created: '2011-03-24 04:57:27',
        updated: '2011-03-24 04:57:58',
        url: 'varosnezes-az-okori-romaban',
        title: 'Városnézés az ókori Rómában',
        section: 2,
        counter: 9192,
    },
    {
        id: 37,
        created: '2011-03-24 04:57:27',
        updated: '2011-03-24 04:57:58',
        url: 'unnepek-es-istenek',
        title: 'Ünnepek és istenek',
        section: 2,
        counter: 9571,
    },
    {
        id: 38,
        created: '2011-03-24 04:57:27',
        updated: '2011-03-24 04:57:59',
        url: 'oktatas-es-kultura-az-okori-romaban',
        title: 'Oktatás és kultúra az ókori rómában',
        section: 2,
        counter: 13369,
    },
    {
        id: 39,
        created: '2011-03-24 04:57:27',
        updated: '2011-03-24 04:57:59',
        url: 'romaiak-hazank-teruleten',
        title: 'Rómaiak hazánk területén',
        section: 2,
        counter: 11064,
    },
    {
        id: 40,
        created: '2011-03-24 04:57:27',
        updated: '2011-03-24 04:57:59',
        url: 'a-keresztenyseg-szuletese',
        title: 'A kereszténység születése',
        section: 2,
        counter: 11796,
    },
    {
        id: 41,
        created: '2011-03-24 04:57:28',
        updated: '2011-03-24 04:58:00',
        url: 'a-keresztenyseg-es-a-birodalom',
        title: 'A kereszténység és a birodalom',
        section: 2,
        counter: 8615,
    },
    {
        id: 42,
        created: '2011-03-24 04:57:28',
        updated: '2011-03-24 04:58:00',
        url: 'a-csaszarsag-valsaga-es-a-romai-birodalom-bukasa',
        title: 'A császárság válsága és a római birodalom bukása',
        section: 2,
        counter: 29410,
    },
    {
        id: 43,
        created: '2011-03-24 04:57:28',
        updated: '2011-03-24 04:58:01',
        url: 'nepek-atutazoban',
        title: 'Népek átutazóban',
        section: 5,
        counter: 6514,
    },
    {
        id: 44,
        created: '2011-03-24 04:57:28',
        updated: '2011-03-24 04:58:01',
        url: 'a-vandorlas-kezdete',
        title: 'A vándorlás kezdete',
        section: 5,
        counter: 10134,
    },
    {
        id: 45,
        created: '2011-03-24 04:57:28',
        updated: '2011-03-24 04:58:02',
        url: '_____ugy-gondoltak-az-allatok-legeltetesere-igen-alkalmas___',
        title: '...Úgy gondolták az állatok legeltetésére igen alkalmas.',
        section: 5,
        counter: 7364,
    },
    {
        id: 46,
        created: '2011-03-24 04:57:28',
        updated: '2011-03-24 04:58:02',
        url: 'levedia-es-etelkoz',
        title: 'Levédia és etelköz',
        section: 5,
        counter: 8791,
    },
    {
        id: 47,
        created: '2011-03-24 04:57:28',
        updated: '2011-03-24 04:58:03',
        url: 'a-honfoglalas',
        title: 'A honfoglalás',
        section: 5,
        counter: 20947,
    },
    {
        id: 49,
        created: '2011-03-24 04:57:29',
        updated: '2011-03-24 04:58:03',
        url: 'a-kozepkor-es-szereploi',
        title: 'A középkor és szereplői',
        section: 6,
        counter: 13457,
    },
    {
        id: 50,
        created: '2011-03-24 04:57:29',
        updated: '2011-03-24 04:58:04',
        url: '__imadkozzal-es-dolgozzal___',
        title: 'Imádkozzál és dolgozzál!',
        section: 6,
        counter: 9804,
    },
    {
        id: 51,
        created: '2011-03-24 04:57:29',
        updated: '2011-03-24 04:58:04',
        url: 'mi-maradt-a-romai-birodalombol_',
        title: 'Mi maradt a római birodalomból?',
        section: 6,
        counter: 10774,
    },
    {
        id: 52,
        created: '2011-03-24 04:57:29',
        updated: '2011-03-24 04:58:05',
        url: 'nagy-karoly-udvaraban',
        title: 'Nagy Károly udvarában',
        section: 6,
        counter: 21111,
    },
    {
        id: 53,
        created: '2011-03-24 04:57:29',
        updated: '2011-03-24 04:58:06',
        url: 'varosok-es-lakoik',
        title: 'Városok és lakóik',
        section: 6,
        counter: 13097,
    },
    {
        id: 54,
        created: '2011-03-24 04:57:29',
        updated: '2011-03-24 04:58:06',
        url: 'vallas-es-birodalom_-az-iszlam',
        title: 'Vallás és birodalom: Az iszlám',
        section: 6,
        counter: 12078,
    },
    {
        id: 55,
        created: '2011-03-24 04:57:29',
        updated: '2011-03-24 04:58:07',
        url: 'a-falvak-es-lakoik',
        title: 'A falvak és lakóik',
        section: 6,
        counter: 9855,
    },
    {
        id: 56,
        created: '2011-03-24 04:57:30',
        updated: '2013-03-01 16:11:03',
        url: 'a-varak-urai',
        title: 'A várak urai',
        section: 6,
        counter: 12017,
    },
    {
        id: 57,
        created: '2011-03-24 04:57:30',
        updated: '2013-03-01 16:12:20',
        url: 'a-rendiseg-kialakulasa',
        title: 'A rendiség kialakulása ',
        section: 6,
        counter: 13452,
    },
    {
        id: 59,
        created: '2011-04-14 17:27:10',
        updated: '2011-04-14 17:27:10',
        url: 'a-kiralyi-hatalom-valsaga-es-megszilardulasa',
        title: 'A királyi hatalom válsága és megszilárdulása',
        section: 7,
        counter: 7056,
    },
    {
        id: 60,
        created: '2011-04-14 17:28:30',
        updated: '2011-04-14 17:28:30',
        url: 'a-mohacshoz-vezeto-ut',
        title: 'A mohácshoz vezető út',
        section: 7,
        counter: 7997,
    },
    {
        id: 61,
        created: '2011-04-14 17:30:06',
        updated: '2013-03-01 16:14:39',
        url: 'a-torokvero-hunyadi-janos',
        title: 'A törökverő Hunyadi János',
        section: 7,
        counter: 9734,
    },
    {
        id: 63,
        created: '2011-12-08 17:31:59',
        updated: '2011-12-08 17:32:45',
        url: 'gazdasag-es-tarsadalom-a-14.-szazadban',
        title: 'Gazdaság és társadalom a 14. században',
        section: 7,
        counter: 6749,
    },
    {
        id: 75,
        created: '2013-02-03 15:45:52',
        updated: '2013-02-03 15:45:52',
        url: 'nagy-lajos--a-lovagkiraly',
        title: 'Nagy Lajos, a lovagkirály',
        section: 7,
        counter: 9122,
    },
    {
        id: 76,
        created: '2013-02-03 15:47:08',
        updated: '2013-02-03 15:47:08',
        url: 'vendegsegek-matyas-udvaraban',
        title: 'Vendégségek Mátyás udvarában',
        section: 7,
        counter: 6023,
    },
    {
        id: 77,
        created: '2013-02-03 15:49:18',
        updated: '2013-03-01 16:22:18',
        url: 'a-nagy-foldrajzi-felfedezesek',
        title: 'A nagy földrajzi felfedezések',
        section: 10001,
        counter: 12090,
    },
    {
        id: 78,
        created: '2013-02-03 15:51:10',
        updated: '2013-02-03 15:51:10',
        url: 'a--napkiralyok--szazada',
        title: 'A "napkirályok" százada',
        section: 10001,
        counter: 7551,
    },
    {
        id: 79,
        created: '2013-02-03 15:55:07',
        updated: '2013-02-03 15:55:07',
        url: 'a-spanyol-vilagbirodalom-es-nemetalfold',
        title: 'A spanyol világbirodalom és Németalföld',
        section: 10001,
        counter: 5861,
    },
    {
        id: 80,
        created: '2013-02-03 15:58:37',
        updated: '2013-03-01 14:28:40',
        url: 'a-tengerek-kiralynoje--anglia',
        title: 'A tengerek királynője, Anglia',
        section: 10001,
        counter: 7463,
    },
    {
        id: 81,
        created: '2013-02-03 15:59:52',
        updated: '2013-02-03 15:59:52',
        url: 'a-vallas-megujulasa',
        title: 'A vallás megújulása',
        section: 10001,
        counter: 8663,
    },
    {
        id: 82,
        created: '2013-02-03 16:00:08',
        updated: '2013-03-01 16:18:35',
        url: 'a-vilaggazdasag-kialakulasa',
        title: 'A világgazdaság kialakulása',
        section: 10001,
        counter: 9881,
    },
    {
        id: 187,
        created: '2013-03-01 14:49:15',
        updated: '2013-03-01 14:49:15',
        url: 'ujrakezdes-a-haboru-utan',
        title: 'Újrakezdés a háború után',
        section: 10022,
        counter: 5410,
    },
    {
        id: 83,
        created: '2013-02-03 16:00:43',
        updated: '2013-02-03 16:00:43',
        url: 'amerika-oslakoi--az-indianok',
        title: 'Amerika őslakói, az indiánok',
        section: 10001,
        counter: 6651,
    },
    {
        id: 84,
        created: '2013-02-03 16:01:10',
        updated: '2013-02-03 16:01:10',
        url: 'tudomany-es-kultura-az-ujkor-kezdeten',
        title: 'Tudomány és kultúra az újkor kezdetén',
        section: 10001,
        counter: 6664,
    },
    {
        id: 85,
        created: '2013-02-12 15:04:05',
        updated: '2013-03-01 19:50:55',
        url: 'a-tatarjaras-es-az-ujjaepites',
        title: 'A tatárjárás és az újjáépítés',
        section: 10002,
        counter: 7047,
    },
    {
        id: 86,
        created: '2013-02-12 15:05:01',
        updated: '2013-03-01 19:48:16',
        url: 'a-valtozasok-kora',
        title: 'A változások kora',
        section: 10002,
        counter: 5386,
    },
    {
        id: 87,
        created: '2013-02-12 15:05:12',
        updated: '2013-02-12 15:05:12',
        url: 'arpad-kori-emlekeink',
        title: 'Árpád-kori emlékeink',
        section: 10002,
        counter: 11495,
    },
    {
        id: 88,
        created: '2013-02-12 15:05:39',
        updated: '2013-03-01 19:53:12',
        url: 'az-allamalapitas',
        title: 'Az államalapítás',
        section: 10002,
        counter: 10239,
    },
    {
        id: 89,
        created: '2013-02-12 15:06:10',
        updated: '2013-03-01 19:56:02',
        url: 'idegen-hatalmak-arnyekaban',
        title: 'Idegen hatalmak árnyékában',
        section: 10002,
        counter: 3788,
    },
    {
        id: 90,
        created: '2013-02-12 15:06:55',
        updated: '2013-03-01 15:12:15',
        url: 'iv.-bela-es-a-tatarveszely',
        title: 'IV. Béla és a tatárveszély',
        section: 10002,
        counter: 5491,
    },
    {
        id: 91,
        created: '2013-02-12 15:07:24',
        updated: '2013-03-01 19:58:15',
        url: 'megtelepedes-es-kalandozasok',
        title: 'Megtelepedés és kalandozások',
        section: 10002,
        counter: 7469,
    },
    {
        id: 92,
        created: '2013-02-12 15:09:28',
        updated: '2013-03-02 13:45:19',
        url: 'szent-istvan-orokeben',
        title: 'Szent István örökében',
        section: 10002,
        counter: 10805,
    },
    {
        id: 93,
        created: '2013-02-12 15:16:52',
        updated: '2013-02-12 15:16:52',
        url: 'a-rakoczi-szabadsagharc',
        title: 'A Rákóczi-szabadságharc',
        section: 10003,
        counter: 14714,
    },
    {
        id: 94,
        created: '2013-02-12 15:17:29',
        updated: '2013-02-12 15:17:29',
        url: 'a-torok-kiuzese',
        title: 'A török kiűzése',
        section: 10003,
        counter: 16241,
    },
    {
        id: 95,
        created: '2013-02-12 15:17:48',
        updated: '2013-02-12 15:17:48',
        url: 'a-varhaboruk-kora',
        title: 'A várháborúk kora',
        section: 10003,
        counter: 9391,
    },
    {
        id: 96,
        created: '2013-02-12 15:18:17',
        updated: '2013-03-01 16:26:02',
        url: 'a-hosszu-haboru-es-a-bocskai-felkeles',
        title: 'A hosszú háború és a Bocskai-felkelés',
        section: 10003,
        counter: 13309,
    },
    {
        id: 97,
        created: '2013-02-12 15:18:58',
        updated: '2013-02-12 15:18:58',
        url: 'ami-osszetartotta-a-harom-reszre-szakadt-orszagot',
        title: 'Ami összetartotta a három részre szakadt országot',
        section: 10003,
        counter: 5709,
    },
    {
        id: 98,
        created: '2013-02-12 15:20:41',
        updated: '2013-02-12 15:20:41',
        url: 'az-orszag-harom-reszre-szakad',
        title: 'Az ország három részre szakad',
        section: 10003,
        counter: 15442,
    },
    {
        id: 99,
        created: '2013-02-12 15:22:13',
        updated: '2013-02-12 15:22:13',
        url: 'elet-a-hodoltsag-teruleten',
        title: 'Élet a hódoltság területén',
        section: 10003,
        counter: 6843,
    },
    {
        id: 100,
        created: '2013-02-12 15:23:10',
        updated: '2013-02-12 15:23:10',
        url: 'erdely-a-17.-szazadban',
        title: 'Erdély a 17. században',
        section: 10003,
        counter: 8410,
    },
    {
        id: 101,
        created: '2013-02-12 15:24:44',
        updated: '2013-03-01 16:24:42',
        url: 'a-habsburg--es-torokellenes-kuzdelmek',
        title: 'A Habsburg- és törökellenes küzdelmek',
        section: 10003,
        counter: 9718,
    },
    {
        id: 102,
        created: '2013-02-12 15:25:57',
        updated: '2013-02-12 15:25:57',
        url: 'kiralysag-es-fejedelemseg',
        title: 'Királyság és fejedelemség',
        section: 10003,
        counter: 12930,
    },
    {
        id: 103,
        created: '2013-02-12 18:33:03',
        updated: '2013-02-12 18:33:03',
        url: 'a-19.-szazadi-vilagbirodalom',
        title: 'A 19. századi világbirodalom',
        section: 10010,
        counter: 6654,
    },
    {
        id: 104,
        created: '2013-02-12 18:34:33',
        updated: '2013-03-02 13:53:41',
        url: 'a--kis--napoleon-franciaorszaga',
        title: 'A "kis" Napóleon Franciaországa',
        section: 10010,
        counter: 5297,
    },
    {
        id: 105,
        created: '2013-02-12 18:36:21',
        updated: '2013-03-02 13:47:32',
        url: 'az-olasz-egyseg-letrejotte',
        title: 'Az olasz egység létrejötte',
        section: 10010,
        counter: 13470,
    },
    {
        id: 186,
        created: '2013-03-01 14:48:45',
        updated: '2013-03-01 14:48:45',
        url: 'megtorlas-es-konszolidacio',
        title: 'Megtorlás és konszolidáció',
        section: 10022,
        counter: 5060,
    },
    {
        id: 106,
        created: '2013-02-19 16:01:15',
        updated: '2013-02-19 16:01:15',
        url: 'a-modern-allam-es-polgarai',
        title: 'A modern állam és polgárai',
        section: 10010,
        counter: 5456,
    },
    {
        id: 107,
        created: '2013-02-19 16:02:04',
        updated: '2013-03-01 14:31:00',
        url: 'a-munkasmozgalom-iranyzatai',
        title: 'A munkásmozgalom irányzatai',
        section: 10010,
        counter: 9633,
    },
    {
        id: 108,
        created: '2013-02-19 16:03:07',
        updated: '2013-03-02 13:55:33',
        url: 'a-nemet-egyseg-megteremtese',
        title: 'A német egység megteremtése',
        section: 10010,
        counter: 7670,
    },
    {
        id: 109,
        created: '2013-02-20 16:19:51',
        updated: '2013-03-01 15:14:59',
        url: 'az-amerikai-egyesult-allamok-polgarhaboruja',
        title: 'Az Amerikai Egyesült Államok polgárháborúja',
        section: 10010,
        counter: 7761,
    },
    {
        id: 110,
        created: '2013-02-20 16:21:02',
        updated: '2013-02-20 16:21:02',
        url: 'az-imperializmus-kora',
        title: 'Az imperializmus kora',
        section: 10010,
        counter: 7975,
    },
    {
        id: 111,
        created: '2013-02-20 16:22:08',
        updated: '2013-03-01 15:13:26',
        url: 'az-ipari-forradalom-terhoditasa',
        title: 'Az ipari forradalom térhódítása',
        section: 10010,
        counter: 9769,
    },
    {
        id: 112,
        created: '2013-02-26 15:32:33',
        updated: '2013-03-02 13:57:41',
        url: 'a-reformkor',
        title: 'A reformkor',
        section: 10011,
        counter: 10027,
    },
    {
        id: 113,
        created: '2013-02-26 15:32:45',
        updated: '2013-03-02 13:58:50',
        url: 'a-feudalis-gazdasag-bomlasa',
        title: 'A feudális gazdaság bomlása',
        section: 10011,
        counter: 4252,
    },
    {
        id: 114,
        created: '2013-02-26 15:32:54',
        updated: '2013-02-26 15:32:54',
        url: 'a-valtozo-eletmod',
        title: 'A változó életmód',
        section: 10011,
        counter: 3673,
    },
    {
        id: 115,
        created: '2013-02-26 15:33:09',
        updated: '2013-03-02 14:00:51',
        url: 'az-aprilisi-torvenyek-es-batthyany-kormany-mukodese',
        title: 'Az áprilisi törvények és Batthyány-kormány működése',
        section: 10011,
        counter: 7056,
    },
    {
        id: 116,
        created: '2013-02-27 07:26:02',
        updated: '2013-02-27 07:26:02',
        url: 'kossuth-lajos--a-nemes-palyatars',
        title: 'Kossuth Lajos, a nemes pályatárs',
        section: 10011,
        counter: 7054,
    },
    {
        id: 117,
        created: '2013-02-27 07:26:21',
        updated: '2013-02-27 07:26:21',
        url: 'magyarorszag-az-atalakulas-kapujaban',
        title: 'Magyarország az átalakulás kapujában',
        section: 10011,
        counter: 5894,
    },
    {
        id: 118,
        created: '2013-02-27 17:58:50',
        updated: '2013-02-27 17:58:50',
        url: 'onvedelmi-haboru-es-szabadsagharc',
        title: 'Önvédelmi háború és szabadságharc',
        section: 10011,
        counter: 7480,
    },
    {
        id: 119,
        created: '2013-02-27 18:00:01',
        updated: '2013-02-27 18:00:01',
        url: 'szechenyi-istvan--a-reformok-elso-megfogalmozoja',
        title: 'Széchenyi István, a reformok első megfogalmozója',
        section: 10011,
        counter: 8646,
    },
    {
        id: 120,
        created: '2013-02-27 18:02:33',
        updated: '2013-02-27 18:03:49',
        url: 'uj-jelensegek-a-tarsadalomban',
        title: 'Új jelenségek a társadalomban',
        section: 10011,
        counter: 4125,
    },
    {
        id: 121,
        created: '2013-02-27 18:04:02',
        updated: '2013-03-02 14:02:09',
        url: 'vereseg-es-megtorlas',
        title: 'Vereség és megtorlás',
        section: 10011,
        counter: 6646,
    },
    {
        id: 122,
        created: '2013-02-27 18:18:00',
        updated: '2013-02-27 18:18:00',
        url: 'a-dualizmus-koranak-politikai-valtozasai',
        title: 'A dualizmus korának politikai változásai',
        section: 10012,
        counter: 7042,
    },
    {
        id: 123,
        created: '2013-02-27 18:18:21',
        updated: '2013-02-27 18:18:21',
        url: 'az-oktatas-fejlodese',
        title: 'Az oktatás fejlődése',
        section: 10012,
        counter: 4529,
    },
    {
        id: 124,
        created: '2013-02-27 18:18:25',
        updated: '2013-02-27 18:18:25',
        url: 'a-polgarosodo-tarsadalom',
        title: 'A polgárosodó társadalom',
        section: 10012,
        counter: 5191,
    },
    {
        id: 125,
        created: '2013-02-27 18:18:33',
        updated: '2013-02-27 18:18:33',
        url: 'a-magyar-gazdasag-fejlodese',
        title: 'A magyar gazdaság fejlődése',
        section: 10012,
        counter: 6652,
    },
    {
        id: 126,
        created: '2013-02-27 18:18:39',
        updated: '2013-02-27 18:18:39',
        url: 'a-kiegyezes',
        title: 'A kiegyezés',
        section: 10012,
        counter: 10715,
    },
    {
        id: 127,
        created: '2013-02-28 15:29:24',
        updated: '2013-02-28 15:29:24',
        url: 'az-orszag-fovarosa--budapest',
        title: 'Az ország fővárosa, Budapest',
        section: 10012,
        counter: 5090,
    },
    {
        id: 128,
        created: '2013-02-28 15:29:32',
        updated: '2013-02-28 15:29:32',
        url: 'az-onkenyuralom-evei',
        title: 'Az önkényuralom évei',
        section: 10012,
        counter: 10673,
    },
    {
        id: 129,
        created: '2013-02-28 15:32:34',
        updated: '2013-02-28 15:32:34',
        url: 'a-felvilagosult-uralkodok-kora',
        title: 'A felvilágosult uralkodók kora',
        section: 10013,
        counter: 4477,
    },
    {
        id: 130,
        created: '2013-02-28 15:36:40',
        updated: '2013-02-28 15:36:40',
        url: 'a-nagy-francia-forradalom.-a-koztarsasag-kora',
        title: 'A nagy francia forradalom. A köztársaság kora',
        section: 10013,
        counter: 5428,
    },
    {
        id: 131,
        created: '2013-02-28 15:37:08',
        updated: '2013-02-28 15:37:08',
        url: 'a-nagy-francia-forradalom.-a-kiralysag-bukasa',
        title: 'A nagy francia forradalom. A királyság bukása',
        section: 10013,
        counter: 20293,
    },
    {
        id: 132,
        created: '2013-02-28 15:37:36',
        updated: '2013-02-28 15:37:36',
        url: 'az-eszak-amerikai-fuggetlensegi-haboru',
        title: 'Az észak-amerikai függetlenségi háború',
        section: 10013,
        counter: 10100,
    },
    {
        id: 133,
        created: '2013-02-28 15:38:07',
        updated: '2013-02-28 15:38:07',
        url: 'a-forradalmak-kora',
        title: 'A forradalmak kora',
        section: 10013,
        counter: 6326,
    },
    {
        id: 134,
        created: '2013-02-28 15:38:35',
        updated: '2013-02-28 15:38:35',
        url: 'a--feny-szazada-',
        title: 'A "fény százada"',
        section: 10013,
        counter: 6728,
    },
    {
        id: 135,
        created: '2013-02-28 15:41:39',
        updated: '2013-02-28 15:41:39',
        url: 'az-ipari-forradalom',
        title: 'Az ipari forradalom',
        section: 10013,
        counter: 8282,
    },
    {
        id: 136,
        created: '2013-02-28 15:42:01',
        updated: '2013-02-28 15:42:01',
        url: 'az-ipari-forradalom-tarsadalmi-kovetkezmenyei',
        title: 'Az ipari forradalom társadalmi következményei',
        section: 10013,
        counter: 5736,
    },
    {
        id: 137,
        created: '2013-02-28 15:42:16',
        updated: '2013-02-28 15:42:16',
        url: 'napoleon--europa-ura',
        title: 'Napóleon, Európa ura',
        section: 10013,
        counter: 11161,
    },
    {
        id: 138,
        created: '2013-02-28 15:48:40',
        updated: '2013-02-28 15:48:40',
        url: 'az-orszag-gazdasaga',
        title: 'Az ország gazdasága',
        section: 10014,
        counter: 3456,
    },
    {
        id: 139,
        created: '2013-02-28 15:48:58',
        updated: '2013-02-28 15:48:58',
        url: 'ii.-jozsef--a--kalapos-kiraly-',
        title: 'II. József, a "kalapos király"',
        section: 10014,
        counter: 5870,
    },
    {
        id: 140,
        created: '2013-02-28 15:49:31',
        updated: '2013-02-28 15:49:31',
        url: 'magyarorszag-a-habsburg-birodalomban',
        title: 'Magyarország a Habsburg Birodalomban',
        section: 10014,
        counter: 8144,
    },
    {
        id: 141,
        created: '2013-02-28 15:49:51',
        updated: '2013-02-28 15:49:51',
        url: 'magyarorszag-a-nagy-valtozasok-koraban',
        title: 'Magyarország a nagy változások korában',
        section: 10014,
        counter: 4662,
    },
    {
        id: 142,
        created: '2013-02-28 15:51:47',
        updated: '2013-02-28 15:51:47',
        url: 'tarsadalmi-viszonyok-a-18.-szazadban',
        title: 'Társadalmi viszonyok a 18. században',
        section: 10014,
        counter: 4241,
    },
    {
        id: 143,
        created: '2013-02-28 15:52:17',
        updated: '2013-02-28 15:52:17',
        url: 'maria-terezia--a--josagos-uralkodono-',
        title: 'Mária Terézia, a "jóságos uralkodónő"',
        section: 10014,
        counter: 8495,
    },
    {
        id: 144,
        created: '2013-02-28 15:56:08',
        updated: '2013-02-28 15:56:08',
        url: 'a-frontok-es-a-hatorszag',
        title: 'A frontok és a hátország',
        section: 10015,
        counter: 6878,
    },
    {
        id: 145,
        created: '2013-02-28 15:56:24',
        updated: '2013-02-28 15:56:24',
        url: 'az-elso-vilaghaboru-befejezese',
        title: 'Az első világháború befejezése',
        section: 10015,
        counter: 6335,
    },
    {
        id: 146,
        created: '2013-02-28 15:56:45',
        updated: '2013-02-28 15:56:45',
        url: 'az-elso-vilaghaboru-kitorese',
        title: 'Az első világháború kitörése',
        section: 10015,
        counter: 12473,
    },
    {
        id: 147,
        created: '2013-02-28 15:57:06',
        updated: '2013-02-28 15:57:06',
        url: 'az-oroszorszagi-forradalom',
        title: 'Az oroszországi forradalom',
        section: 10015,
        counter: 6002,
    },
    {
        id: 148,
        created: '2013-02-28 15:58:03',
        updated: '2013-02-28 15:58:03',
        url: 'magyarorszag-az-elso-vilaghaboruban',
        title: 'Magyarország az első világháborúban',
        section: 10015,
        counter: 9338,
    },
    {
        id: 149,
        created: '2013-02-28 16:45:55',
        updated: '2013-02-28 16:45:55',
        url: 'a-hideghaboru-kibontakozasa',
        title: 'A hidegháború kibontakozása',
        section: 10016,
        counter: 9623,
    },
    {
        id: 150,
        created: '2013-02-28 16:46:15',
        updated: '2013-02-28 16:46:15',
        url: 'a-hideghaboru-vege',
        title: 'A hidegháború vége',
        section: 10016,
        counter: 5663,
    },
    {
        id: 151,
        created: '2013-02-28 16:46:33',
        updated: '2013-02-28 16:46:33',
        url: 'a-ketpolusu-vilag-kialakulasa',
        title: 'A kétpólusú világ kialakulása',
        section: 10016,
        counter: 8245,
    },
    {
        id: 152,
        created: '2013-02-28 16:46:47',
        updated: '2013-02-28 16:46:47',
        url: 'az-europai-egysegfolyamat',
        title: 'Az európai egységfolyamat',
        section: 10016,
        counter: 4397,
    },
    {
        id: 153,
        created: '2013-02-28 16:47:10',
        updated: '2013-03-01 14:33:37',
        url: 'az-europan-kivuli-vilag',
        title: 'Az Európán kívüli világ',
        section: 10016,
        counter: 7915,
    },
    {
        id: 154,
        created: '2013-02-28 16:47:28',
        updated: '2013-03-02 14:05:14',
        url: 'enyhules-es-szembenallas',
        title: 'Enyhülés és szembenállás',
        section: 10016,
        counter: 5137,
    },
    {
        id: 155,
        created: '2013-02-28 16:53:54',
        updated: '2013-02-28 16:53:54',
        url: 'vilaggazdasagi-valtozasok',
        title: 'Világgazdasági változások',
        section: 10016,
        counter: 3572,
    },
    {
        id: 156,
        created: '2013-02-28 16:54:23',
        updated: '2013-02-28 16:54:23',
        url: 'tarsadalmi-valtozasok',
        title: 'Társadalmi változások',
        section: 10016,
        counter: 3978,
    },
    {
        id: 157,
        created: '2013-02-28 16:54:49',
        updated: '2013-02-28 16:54:49',
        url: 'napjaink-kihivasai',
        title: 'Napjaink kihívásai',
        section: 10016,
        counter: 4567,
    },
    {
        id: 158,
        created: '2013-02-28 17:01:30',
        updated: '2013-09-24 18:10:43',
        url: 'a-semlegessegtol-a-doni-katasztrofaig',
        title: 'A semlegességtől a doni katasztrófáig',
        section: 10019,
        counter: 5907,
    },
    {
        id: 159,
        created: '2013-02-28 17:02:04',
        updated: '2013-02-28 17:02:04',
        url: 'a-villamhaboruk-idoszaka',
        title: 'A villámháborúk időszaka',
        section: 10019,
        counter: 11363,
    },
    {
        id: 160,
        created: '2013-02-28 17:02:44',
        updated: '2013-02-28 17:02:44',
        url: 'az-antifasiszta-koalicio-gyozelme',
        title: 'Az antifasiszta koalíció győzelme',
        section: 10019,
        counter: 13371,
    },
    {
        id: 161,
        created: '2013-02-28 17:03:23',
        updated: '2013-02-28 17:03:23',
        url: 'az-europai-zsidosag-tragediaja',
        title: 'Az európai zsidóság tragédiája',
        section: 10019,
        counter: 6916,
    },
    {
        id: 162,
        created: '2013-02-28 17:04:47',
        updated: '2013-02-28 17:04:47',
        url: 'fordulat-a-haboru-meneteben',
        title: 'Fordulat a háború menetében',
        section: 10019,
        counter: 17307,
    },
    {
        id: 163,
        created: '2013-02-28 17:05:45',
        updated: '2013-02-28 17:05:45',
        url: 'hazank-nemet-megszallasa-es-a-nyilas-uralom',
        title: 'Hazánk német megszállása és a nyilas uralom',
        section: 10019,
        counter: 8527,
    },
    {
        id: 164,
        created: '2013-02-28 17:08:40',
        updated: '2013-02-28 17:08:40',
        url: 'a-bekekotesek-utan',
        title: 'A békekötések után',
        section: 10020,
        counter: 4546,
    },
    {
        id: 165,
        created: '2013-02-28 17:08:58',
        updated: '2013-02-28 17:08:58',
        url: 'a-harmadik-birodalom',
        title: 'A harmadik birodalom',
        section: 10020,
        counter: 24048,
    },
    {
        id: 166,
        created: '2013-02-28 17:09:13',
        updated: '2013-02-28 17:09:13',
        url: 'a-nagy-gazdasagi-valsag',
        title: 'A nagy gazdasági válság',
        section: 10020,
        counter: 10622,
    },
    {
        id: 167,
        created: '2013-02-28 17:10:40',
        updated: '2013-02-28 17:10:40',
        url: 'a-parizs-kornyeki-bekek',
        title: 'A Párizs környéki békék',
        section: 10020,
        counter: 6766,
    },
    {
        id: 168,
        created: '2013-02-28 17:11:42',
        updated: '2013-02-28 17:11:42',
        url: 'a-sztalini-szovjetunio',
        title: 'A sztálini Szovjetunió',
        section: 10020,
        counter: 10501,
    },
    {
        id: 169,
        created: '2013-02-28 17:12:01',
        updated: '2013-02-28 17:12:01',
        url: 'europa-az-elso-vilaghaboru-utan',
        title: 'Európa az első világháború után',
        section: 10020,
        counter: 7802,
    },
    {
        id: 170,
        created: '2013-02-28 17:12:20',
        updated: '2013-02-28 17:12:20',
        url: 'uton-az-ujabb-haboru-fele',
        title: 'Úton az újabb háború felé',
        section: 10020,
        counter: 5699,
    },
    {
        id: 171,
        created: '2013-02-28 17:15:17',
        updated: '2013-02-28 17:15:17',
        url: 'a-rendszer-megszilardulasa',
        title: 'A rendszer megszilárdulása',
        section: 10021,
        counter: 6265,
    },
    {
        id: 172,
        created: '2013-02-28 17:15:43',
        updated: '2013-02-28 17:15:43',
        url: 'a-hetkoznapi-elet',
        title: 'A hétköznapi élet',
        section: 10021,
        counter: 3458,
    },
    {
        id: 173,
        created: '2013-02-28 17:16:05',
        updated: '2013-02-28 17:16:05',
        url: 'a-harmadik-birodalom-arnyekaban',
        title: 'A harmadik birodalom árnyékában',
        section: 10021,
        counter: 7417,
    },
    {
        id: 174,
        created: '2013-02-28 17:16:42',
        updated: '2013-09-24 18:16:02',
        url: 'a-tanacskoztarsasag',
        title: 'A Tanácsköztársaság',
        section: 10021,
        counter: 5991,
    },
    {
        id: 175,
        created: '2013-02-28 17:18:41',
        updated: '2013-02-28 17:18:41',
        url: 'a-trianoni-beke',
        title: 'A trianoni béke',
        section: 10021,
        counter: 14029,
    },
    {
        id: 176,
        created: '2013-02-28 17:21:08',
        updated: '2013-03-02 14:09:11',
        url: 'az-ellenforradalom-es-horthy-hatalomra-jutasa',
        title: 'Az ellenforradalom és Horthy hatalomra jutása',
        section: 10021,
        counter: 7278,
    },
    {
        id: 177,
        created: '2013-02-28 17:21:30',
        updated: '2013-02-28 17:21:30',
        url: 'az-oszirozsas-forradalom',
        title: 'Az őszirózsás forradalom',
        section: 10021,
        counter: 8232,
    },
    {
        id: 178,
        created: '2013-02-28 17:21:49',
        updated: '2013-02-28 17:21:49',
        url: 'tarsadalom-es-kultura',
        title: 'Társadalom és kultúra',
        section: 10021,
        counter: 4618,
    },
    {
        id: 179,
        created: '2013-02-28 17:22:09',
        updated: '2013-03-02 14:07:40',
        url: 'valsag-es-kiutkereses',
        title: 'Válság és kiútkeresés',
        section: 10021,
        counter: 5968,
    },
    {
        id: 180,
        created: '2013-02-28 17:34:06',
        updated: '2013-02-28 17:34:06',
        url: 'a-magyar-rendszervaltas',
        title: 'A magyar rendszerváltás',
        section: 10022,
        counter: 8213,
    },
    {
        id: 181,
        created: '2013-03-01 13:54:20',
        updated: '2013-03-01 14:59:50',
        url: 'a-rakosi-diktatura',
        title: 'A Rákosi-diktatúra',
        section: 10022,
        counter: 6701,
    },
    {
        id: 182,
        created: '2013-03-01 13:54:49',
        updated: '2013-03-01 14:58:38',
        url: 'az-1956-os-forradalom',
        title: 'Az 1956-os forradalom',
        section: 10022,
        counter: 14475,
    },
    {
        id: 183,
        created: '2013-03-01 13:55:43',
        updated: '2013-03-01 14:56:21',
        url: 'az--uj-szakasz--es-kudarca',
        title: 'Az "új szakasz" és kudarca',
        section: 10022,
        counter: 7920,
    },
    {
        id: 184,
        created: '2013-03-01 13:57:14',
        updated: '2013-03-01 14:54:43',
        url: 'kadar-reformjai-es-rendszerenek-valsaga',
        title: 'Kádár reformjai és rendszerének válsága',
        section: 10022,
        counter: 7444,
    },
    {
        id: 185,
        created: '2013-03-01 14:02:35',
        updated: '2013-03-01 14:52:33',
        url: 'magyarorszag-szovjetizalasa',
        title: 'Magyarország szovjetizálása',
        section: 10022,
        counter: 5240,
    },
    {
        id: 188,
        created: '2013-03-01 14:49:43',
        updated: '2013-03-01 14:49:43',
        url: 'tarsadalom-es-kultura.-a-mindennapi-elet',
        title: 'Társadalom és kultúra. A mindennapi élet',
        section: 10022,
        counter: 3963,
    },
];
