import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import HomeIcon from '@mui/icons-material/Home';
import {
    Sidebar,
    Menu,
    SubMenu,
    useProSidebar,
    MenuItem,
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { SECTIONS } from '../assets/sections';

export const SidebarComponent = () => {
    const { toggleSidebar } = useProSidebar();

    const CrawlableMenuItem = ({ onClick, routerLink, children }) => {
        return (
            <li className='side-menu-button'>
                <Link
                    to={routerLink}
                    onClick={onClick}
                    className='side-menu-button-label '
                >
                    {children}
                </Link>
            </li>
        );
    };

    const renderMenuItemsForClass = (classNum: 5 | 6 | 7 | 8) => {
        const icons = [
            <Filter5Icon />,
            <Filter6Icon />,
            <Filter7Icon />,
            <Filter8Icon />,
        ];
        return (
            <SubMenu icon={icons[classNum - 5]} label={`${classNum}. osztály`}>
                {SECTIONS[classNum].map((section) => (
                    <CrawlableMenuItem
                        onClick={() => toggleSidebar()}
                        key={section.id}
                        routerLink={`/sections/${section.id}`}
                    >
                        {section.title}
                    </CrawlableMenuItem>
                ))}
            </SubMenu>
        );
    };

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar breakPoint='always' backgroundColor='white'>
                <Menu>
                    <MenuItem
                        routerLink={<Link to='/' />}
                        onClick={() => toggleSidebar()}
                        icon={<HomeIcon />}
                    >
                        Kezdőlap
                    </MenuItem>
                    {renderMenuItemsForClass(5)}
                    {renderMenuItemsForClass(6)}
                    {renderMenuItemsForClass(7)}
                    {renderMenuItemsForClass(8)}
                </Menu>
            </Sidebar>
        </div>
    );
};
