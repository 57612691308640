import React, { useEffect } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { AppBarWithSearch } from './components/app-bar-with-search';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { SidebarComponent } from './components/sidebar';
import bgImg from './assets/bgCompressed.jpg';
import { buildRoutes } from './helpers/route-builder';
import { pingServer } from './helpers/article-helpers';

function App() {
    const ROUTES = buildRoutes();

    const { pathname } = useLocation();
    useEffect(() => {
        if (!pathname.includes('articles')) return;

        const isNewSession = !sessionStorage.getItem('sessionLive');

        pingServer(pathname.split('/').pop(), isNewSession);
        if (isNewSession) {
            sessionStorage.setItem('sessionLive', 'true');
        }
    }, [pathname]);

    return (
        <div className='App'>
            <div className='bg-img-holder'>
                <img src={bgImg} alt='Background' />
            </div>

            <ProSidebarProvider>
                <AppBarWithSearch />
                <SidebarComponent />
            </ProSidebarProvider>

            <div className='app-content'>
                <Routes>
                    {ROUTES.map((route) => (
                        <Route
                            path={route.path}
                            element={
                                <route.componentFn {...route.componentParams} />
                            }
                            key={route.path}
                        />
                    ))}
                </Routes>
            </div>
        </div>
    );
}

export default App;
