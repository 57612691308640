export const HomePage = () => {
    return (
        <div className='homepage main-content-container'>
            <div className='book-page'>
                <h1>Üdvözlünk az oldalon!</h1>
                <h3 style={{ textAlign: 'center', color: 'gray' }}>
                    Történelem vázlatok 5., 6., 7. és 8. osztályosoknak
                </h3>

                <ul>
                    <li>
                        A témák közt a bal felső sarokban lévő menü gomb
                        segítségével tudsz böngészni.
                    </li>
                    <li>
                        Illetve konkrét anyagra a jobb felső sarokban lévő
                        mezővel tudsz keresni.
                    </li>
                </ul>
            </div>
        </div>
    );
};
