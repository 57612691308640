import { useState } from 'react';
import { Link } from 'react-router-dom';
import { alpha, Box, InputBase, styled, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useProSidebar } from 'react-pro-sidebar';
import Paper from '@mui/material/Paper';
import { searchInTitles } from '../helpers/search-title';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export const AppBarWithSearch = () => {
    const { toggleSidebar } = useProSidebar();
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [searchResults, setSearchResults] = useState([] as any);

    const doSearch = (searchString) => {
        if (searchString.length < 3) {
            setSearchString('');
            setSearchResults([]);
            return;
        }
        setSearchString(searchString);
        setSearchResults(searchInTitles(searchString));
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position='static' color='primary'>
                <Toolbar>
                    <IconButton
                        size='large'
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        sx={{ mr: 2 }}
                        onClick={() => toggleSidebar()}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant='h6'
                        noWrap
                        component='div'
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', sm: 'block' },
                            textAlign: 'left',
                        }}
                    >
                        Történelem vázlatok (v2 BETA)
                    </Typography>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder='Keresés...'
                            inputProps={{ 'aria-label': 'search' }}
                            onFocus={() => setIsSearchOpen(true)}
                            onBlur={() => setIsSearchOpen(false)}
                            onChange={(e) => doSearch(e.target.value)}
                        />
                        <div
                            className={`search-results-container${
                                isSearchOpen ? '' : ' hidden'
                            }`}
                        >
                            <Paper elevation={8}>
                                <div className='search-results'>
                                    {!!searchResults?.length && (
                                        <div>
                                            <h2 style={{ marginTop: 0 }}>
                                                Keresési találatok:
                                            </h2>
                                            {searchResults.map((articleDef) => (
                                                <div
                                                    key={articleDef.id}
                                                    className='search-result-link'
                                                >
                                                    <Link
                                                        to={`/sections/search/articles/${articleDef.url}`}
                                                    >
                                                        <ArrowForwardIcon />
                                                        {articleDef.title}
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {!!searchString &&
                                        !searchResults.length && (
                                            <div>Nincs találat.</div>
                                        )}

                                    {!searchString && (
                                        <div>
                                            Kezdd el begépelni egy anyag
                                            címét...
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        </div>
                    </Search>
                </Toolbar>
            </AppBar>
        </Box>
    );
};
