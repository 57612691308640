import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchArticleContent } from '../helpers/article-helpers';
import './article-reader.css';

export const ArticleReader = ({ article }) => {
    useEffect(() => {
        fetchArticleContent(article.url).then(setArticleContent);
    }, [article.url]);

    const [articleContent, setArticleContent] = useState('');

    return (
        <div className='article-reader main-content-container'>
            <div className='book-page'>
                {!!articleContent ? (
                    <>
                        <h1>{article.title}</h1>
                        <div className='divider div-transparent div-dot'></div>
                        <div
                            className='article-content'
                            dangerouslySetInnerHTML={{ __html: articleContent }}
                        ></div>
                    </>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={80} />
                    </div>
                )}
            </div>
        </div>
    );
};
